import { FC } from 'react';

import { Box } from '@mui/material';
import FileMessageItemBody from '@sendbird/uikit-react/ui/FileMessageItemBody';
import { ClientFileMessage } from '@sendbird/uikit-react/types/types';

import { styles } from './styles';

interface SearchFileMessageItemProps {
  isCurrentUser: boolean;
  isCurrentCompany: boolean;
  message: ClientFileMessage;
}

export const SearchFileMessageItem: FC<SearchFileMessageItemProps> = ({ isCurrentUser, isCurrentCompany, message }) => {
  return (
    <Box sx={styles.wrap(isCurrentUser || isCurrentCompany)}>
      <FileMessageItemBody message={message} mouseHover={false} />
    </Box>
  );
};
