import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';

import { URLSearchParamsInit, createSearchParams, generatePath, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import { RESTRICTED_CROPS } from 'app/api/crops';
import { ProductDetail, getProductData, PRODUCT_NOT_FOUND_ERROR } from 'app/api/products';
import { getDefaultUserRoute } from 'app/providers/utils';
import { ROUTES } from 'app/routes/constants';
import { useAuth, useDeviceType } from 'hooks';
import { getFullTechStats, getKeyTechStats } from 'utils/productDetailsUtils';
import { AnalyticsLinkType, useLinkClickedEvent } from 'analytics';
import { notificationObserver } from 'utils/observer';
import { useQuoteRequestsQuery } from 'pages/DashboardPage/hooks/useQuoteRequestsQuery';
import { SortTypes } from 'pages/DashboardPage/types';
import { QuoteRequestStatus, UnitsType } from 'app/api/quotes';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { CropState, selectCropByName } from 'state/slices/cropFamilies';
import { setSignupModal } from 'state/slices/account';
import * as tracker from 'analytics/tracker';
import { TechStatData } from '../types';

interface ProductDetailsPageHandlers {
  crop: CropState | undefined;
  isBuyer: boolean;
  isLoading: boolean;
  data?: ProductDetail;
  hideRequestButton: boolean;
  keyTechStats: TechStatData;
  fullTechStats: TechStatData;
  isVerticalImage: boolean | null;
  legendImage?: string | null;
  onRequestQuoteClick: () => void;
  handleImageLoad: (e: SyntheticEvent) => void;
}

const HIDDEN_STATS = [''];
const shouldHideStat = (name: string) => {
  return HIDDEN_STATS.some((stat) => name.toLowerCase() === stat.toLowerCase());
};

export const useProductDetailsPageHandlers = (): ProductDetailsPageHandlers => {
  const { isMobileSize } = useDeviceType();
  const [isVerticalImage, setIsVerticalImage] = useState<boolean | null>(null);

  const { isBuyer, activeUser } = useAuth();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { handleTrackLinkClickEvent } = useLinkClickedEvent();

  const { isLoading, data } = useQuery(['company', id], () => getProductData(Number(id)), {
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: (data) => {
      const isRestricted = RESTRICTED_CROPS.some((crop) => crop === data.crop.toLowerCase());

      if (isRestricted) {
        notificationObserver.publish({
          type: 'error',
          title: PRODUCT_NOT_FOUND_ERROR,
        });
        navigate(getDefaultUserRoute(isBuyer));
      }
      return data;
    },
    onError: () => {
      notificationObserver.publish({
        type: 'error',
        title: PRODUCT_NOT_FOUND_ERROR,
      });
      navigate(getDefaultUserRoute(isBuyer));
    },
  });
  const crop = useAppSelector(selectCropByName(data?.crop || ''));

  const [sendPageEventId, setSendPageEventId] = useState<string | null>(null);
  useEffect(() => {
    if (sendPageEventId !== id && data && data?.id.toString() === id && crop) {
      tracker.page({
        page: 'ProductDetailsPage',
        productId: data.id,
        crop: data.crop,
        cropFamily: crop.family.name,
        variety: data.variety,
        name: data.name,
      });
      setSendPageEventId(id);
    }
  }, [id, isLoading, data, crop, sendPageEventId, setSendPageEventId]);

  const sellerCompanyId = Number(data?.company.id);

  const { data: quotes } = useQuoteRequestsQuery({
    enabled: !!sellerCompanyId && !!activeUser,
    sortValue: SortTypes.DEFAULT,
    status: [QuoteRequestStatus.OPEN],
    sellerCompanyId,
  });
  const dispatch = useAppDispatch();

  const onRequestQuoteClick = useCallback(
    (quantity?: number, units?: UnitsType) => {
      if (!activeUser) {
        dispatch(
          setSignupModal({
            isOpen: true,
            state: 'requestAccess',
            source: 'quote',
            metadata: {
              quantity,
              units: units || UnitsType.KG,
              product: data,
            },
          }),
        );
        return;
      }
      const existingQuoteRequest = quotes?.find((quote) => !quote.isSampleType);
      if (existingQuoteRequest) {
        const path = generatePath(ROUTES.buyer.quoteRequestAdd, {
          companyDisplayId: data?.company.displayId,
          quoteId: existingQuoteRequest.id,
        });
        const params: { productId?: string; quantity?: number; units?: UnitsType } = { productId: id };
        if (quantity) params.quantity = quantity;
        if (units) params.units = units;
        navigate({
          pathname: path,
          search: createSearchParams(params as URLSearchParamsInit).toString(),
        });
        handleTrackLinkClickEvent({ path, text: 'Request Quote', type: AnalyticsLinkType.BUTTON });
      } else {
        const path = generatePath(ROUTES.buyer.quoteRequest, {
          companyDisplayId: data?.company.displayId,
          productId: id,
        });
        const params: { quantity?: number; units?: UnitsType } = {};
        if (quantity) params.quantity = quantity;
        if (units) params.units = units;
        navigate({ pathname: path, search: createSearchParams(params as URLSearchParamsInit).toString() });
        handleTrackLinkClickEvent({ path, text: 'Request Quote', type: AnalyticsLinkType.BUTTON });
      }
    },
    [data, id, navigate, handleTrackLinkClickEvent, quotes, activeUser, dispatch],
  );

  const productMainAttributes = useMemo(
    () =>
      data
        ? Object.entries(data)
            .filter(([key]) => key !== 'other')
            .map(([key, value]) => ({ name: key, value }))
        : [],
    [data],
  );

  const productOtherAttributes = useMemo(
    () =>
      data?.other
        ? Object.entries(data.other)
            .filter(([key, value]) => !!key && !!value)
            .map(([key, value]) => ({ name: key, value }))
        : [],
    [data?.other],
  ) as TechStatData;

  const productAllAttributes = [...productMainAttributes, ...productOtherAttributes];
  const keyTechStats = getKeyTechStats(productAllAttributes).filter((stat) => !!activeUser || stat.name !== 'name');
  const keyTechStatKeys = new Set(keyTechStats.map((stat) => stat.name));
  const fullTechStats = getFullTechStats(productAllAttributes, productOtherAttributes).filter(
    (stat) =>
      (!!activeUser || stat.name !== 'name') &&
      (isMobileSize || !keyTechStatKeys.has(stat.name)) &&
      !shouldHideStat(stat.name),
  );

  const handleImageLoad = useCallback((event: SyntheticEvent) => {
    const img = event?.target as HTMLImageElement;
    const width = img?.naturalWidth;
    const height = img?.naturalHeight;
    const acpectRatio = width / height;

    setIsVerticalImage(height > width || acpectRatio < 1.35);
  }, []);
  return {
    crop,
    isBuyer,
    isLoading,
    data,
    keyTechStats,
    fullTechStats,
    hideRequestButton: !isBuyer && !!activeUser,
    isVerticalImage,
    legendImage: data?.image ? data.companyLegendImage : null,
    onRequestQuoteClick,
    handleImageLoad,
  };
};
