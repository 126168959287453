import { generatePath, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useAutocomplete } from '@mui/base/useAutocomplete';
import { Input } from '@mui/base/Input';
import * as tracker from 'analytics/tracker';
import { AnalyticsLinkType, AnalyticsName } from 'analytics';

import { FC, forwardRef, useCallback, useEffect } from 'react';
import { AutocompleteChangeDetails, Box, IconButton, Popper, useForkRef } from '@mui/material';

import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/searchClose.svg';
import { SxPropsTypes } from 'theme/MuiThemeProvider/types';

import { ROUTES } from 'app/routes/constants';
import { CropSearchAutocomplete } from 'app/api/search';
import { SEARCH_TYPE } from 'pages/ProductsListPage/constants';
import { removeExtraSpaces } from 'utils/formatText';
import { styles } from './styles';
import { useSearchAutocomplete } from './hooks';

type SearchOption = { option: string | CropSearchAutocomplete };

export const useSearch = () => {
  const { type } = useParams();
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const { crops, query, setQuery, autocompleteResults, setResults } = useSearchAutocomplete();

  const clearInput = useCallback(() => setQuery(''), [setQuery]);
  const onSearch = useCallback(
    (value: SearchOption | undefined) => {
      if (!value) return;
      if (typeof value.option === 'string') {
        // Case where user does not select any option from the dropdown
        const text = removeExtraSpaces(value.option.trim());
        const encodedText = encodeURIComponent(text);
        if (!text) {
          clearInput();
          return;
        }
        const crop = crops.find((c) => c.name.toLowerCase() === text);
        if (crop) {
          setResults(undefined);
          const path = generatePath(ROUTES.buyer.productsExtended, { type: crop.family.name, subType: crop.name });
          navigate(path);
          return;
        }
        const path = generatePath(ROUTES.buyer.products, { type: SEARCH_TYPE });
        setResults(undefined);
        navigate(`${path}?search=${encodedText}`);
        return;
      }
      setResults(undefined);
      // Case where user selects any option from the dropdown
      const path = generatePath(ROUTES.buyer.productsExtended, {
        type: value.option.familyName,
        subType: value.option.name,
      });

      navigate(path);
      tracker.track(AnalyticsName.LINK_CLICKED, {
        link_text: value.option.name,
        link_type: AnalyticsLinkType.DROPDOWN,
      });
    },
    [navigate, clearInput, setResults, crops],
  );

  useEffect(() => {
    if (type !== SEARCH_TYPE && !searchParams.get(SEARCH_TYPE)) {
      setQuery('');
    } else {
      setQuery(searchParams.get(SEARCH_TYPE) || '');
    }
  }, [type, searchParams, setQuery]);

  const autocomplete = useAutocomplete<CropSearchAutocomplete, false, false, true>({
    freeSolo: true,
    id: 'autocomplete-search',
    options: autocompleteResults?.crops || [],
    getOptionLabel: (option: string | CropSearchAutocomplete) => {
      if (typeof option === 'string') return option;
      return option.name ?? '';
    },
    getOptionDisabled: () => false,
    filterOptions: (options) => options,
    filterSelectedOptions: true,
    onChange: (_, __, ___, option) => onSearch(option as AutocompleteChangeDetails<CropSearchAutocomplete> | undefined),
    inputValue: query,
    onInputChange: (_, newInputValue) => {
      setQuery(newInputValue);
    },
  });

  const inputProps: React.InputHTMLAttributes<HTMLInputElement> & { ref: React.Ref<HTMLInputElement> } =
    autocomplete.getInputProps();
  const onClickSearchIcon = () => {
    if (query !== '') {
      onSearch({ option: query });
    }
  };

  const hasItems = autocompleteResults?.crops?.length > 0;

  return {
    onSearch,
    autocompleteResults,
    autocomplete,
    onClickSearchIcon,
    inputProps,
    hasItems,
    query,
    clearInput,
  };
};

export const HeaderSearch: FC<{ containerStyles?: SxPropsTypes; inputContainerStyles?: SxPropsTypes }> = forwardRef(
  ({ containerStyles = {}, inputContainerStyles = {} }, ref) => {
    const { query, clearInput, autocompleteResults, autocomplete, onClickSearchIcon, inputProps, hasItems } =
      useSearch();
    const rootRef = useForkRef(ref, autocomplete.setAnchorEl);
    return (
      <Box component="div" sx={{ ...styles.container, ...containerStyles }}>
        <Box
          component="div"
          ref={rootRef}
          {...autocomplete.getRootProps()}
          sx={{ ...styles.inputContainer, ...inputContainerStyles }}
        >
          <Input id={autocomplete.id} slotProps={{ input: inputProps }} placeholder="Search for products" />
          {!!query && (
            <IconButton sx={{ ...styles.closeButton }} onClick={clearInput}>
              <CloseIcon height={24} width={24} />
            </IconButton>
          )}

          <Box sx={{ ...styles.searchIcon }}>
            <SearchIcon onClick={onClickSearchIcon} />
          </Box>
        </Box>
        {autocomplete.popupOpen && hasItems ? (
          <Popper
            open={autocomplete.popupOpen}
            anchorEl={autocomplete.anchorEl}
            disablePortal
            sx={styles.popper}
            placement="bottom-start"
            modifiers={[
              { name: 'flip', enabled: false },
              { name: 'preventOverflow', enabled: false },
            ]}
          >
            <Box component="ul" {...autocomplete.getListboxProps()} sx={styles.resultsList}>
              {(autocompleteResults?.crops ?? []).map((option, index) => {
                const { key, ...restAutocompleteProps } = autocomplete.getOptionProps({ option, index });
                return (
                  <Box component="li" sx={styles.result} key={key} {...restAutocompleteProps}>
                    {option.name}
                  </Box>
                );
              })}
            </Box>
          </Popper>
        ) : null}
      </Box>
    );
  },
);
