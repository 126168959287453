import { useCallback, useEffect, useLayoutEffect, useState } from 'react';

import { generatePath, useNavigate, useSearchParams } from 'react-router-dom';
import { ClientSentMessages } from '@sendbird/uikit-react/types/types';

import { ROUTES } from 'app/routes/constants';
import { useAuth, useChannelMetaData, useDeviceType, useCurrentChannelHandlers, useChannelMembers } from 'hooks';
import { getCurrentChannelLogo } from 'utils/chat';
import { ChannelMemeberMetaData } from 'hooks/useChannelMembers';
import { useQuoteRequestsQuery } from 'pages/DashboardPage/hooks/useQuoteRequestsQuery';
import { SortTypes } from 'pages/DashboardPage/types';
import { useOrdersQuery } from 'pages/DashboardPage/hooks/useOrdersQuery';
import { OrderStatus } from 'app/api/orders';

export const useMessagesPageHandlers = () => {
  const { isMobileSize } = useDeviceType();
  const { isBuyer, activeUser, activeUserCompanyId, sendbirdUserId } = useAuth();

  const [currentChannelId, setCurrentChannelId] = useState<string | null>(null);

  const [isConversationInfoPannel, setIsConversationInfoPannel] = useState<boolean>(false);
  const [isSearchMessagesPannel, setIsSearchMessagesPannel] = useState<boolean>(false);

  const [selectedMessage, setSelectedMessage] = useState<ClientSentMessages | undefined>(undefined);
  const [startingPoint, setStartingPoint] = useState<number | undefined>(undefined);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const idSearchParam = searchParams.get('id');

  const { channelSBName, currentChannel, currentChannelMetaData, isChannelExisting, isChannelListLoading } =
    useCurrentChannelHandlers({
      currentChannelId,
      idSearchParam,
    });

  const { currentCompanyMembers, otherCompanyMembers } = useChannelMembers({
    activeUserCompanyId,
    currentChannel,
    sendbirdUserId,
  });

  useEffect(() => {
    if (!idSearchParam) {
      setCurrentChannelId(null);
    }
  }, [currentChannelId, idSearchParam, searchParams]);

  useLayoutEffect(() => {
    if (currentChannelId) {
      setSelectedMessage(undefined);
      setStartingPoint(undefined);
      setIsConversationInfoPannel(false);
      setIsSearchMessagesPannel(false);
    }
  }, [currentChannelId]);

  useLayoutEffect(() => {
    if (startingPoint && !selectedMessage) {
      setStartingPoint(undefined);
    }
  }, [selectedMessage, startingPoint]);

  const { channelLogo, channelName: currentChannelName } = useChannelMetaData({
    channelSBName,
    isBuyer,
    metaData: currentChannelMetaData,
  });

  const isLogoPlaceholder = !channelLogo?.length;
  const currentCompanyName = activeUser?.company.name;
  const currentChannelLogo = getCurrentChannelLogo(isLogoPlaceholder, channelLogo);

  useEffect(() => {
    if (!!idSearchParam && isChannelExisting) {
      setCurrentChannelId(idSearchParam);
    } else if (!!idSearchParam && !isChannelListLoading && !isChannelExisting) {
      setCurrentChannelId(null);
      navigate({});
    }
  }, [idSearchParam, isChannelExisting, isChannelListLoading, navigate]);

  const handleResetSelectedMessage = useCallback(() => {
    if (selectedMessage) {
      setSelectedMessage(undefined);
    }
  }, [selectedMessage]);

  const handleToggleConversationInfo = useCallback(() => {
    setIsConversationInfoPannel((prev) => {
      if (isSearchMessagesPannel && !prev) {
        setIsSearchMessagesPannel(false);
        setSelectedMessage(undefined);
        setStartingPoint(undefined);
      }

      return !prev;
    });
  }, [isSearchMessagesPannel]);

  const handleToggleMessagesSearch = useCallback(() => {
    setIsSearchMessagesPannel((prev) => {
      if (isConversationInfoPannel && !prev) {
        setIsConversationInfoPannel(false);
      }

      if (prev) {
        handleResetSelectedMessage();
      }

      return !prev;
    });
  }, [handleResetSelectedMessage, isConversationInfoPannel]);

  const handleCurrentChannelChange = useCallback(
    (id: string) => {
      if (isMobileSize) {
        const path = generatePath(ROUTES.common.chat, { id });
        navigate(path);
      } else {
        setCurrentChannelId(id);
        setIsConversationInfoPannel(false);
      }
    },
    [isMobileSize, navigate],
  );

  const handleSearchResultClick = useCallback((message: ClientSentMessages) => {
    setSelectedMessage(message);
    setStartingPoint(message.createdAt);
  }, []);

  const otherCompanyId =
    otherCompanyMembers.length > 0
      ? parseInt((otherCompanyMembers[0].metaData as ChannelMemeberMetaData).company_id, 10)
      : undefined;

  const { quotes, isLoading: isQuoteLoading, isSuccess: isQuoteSuccess } = useCompanyQuote({ isBuyer, otherCompanyId });
  const { orders, isLoading: isOrdersLoading } = useCompanyOrder({ isBuyer, otherCompanyId });

  return {
    activeUserCompanyId,
    otherCompanyId,
    quotes,
    orders,
    isOrdersLoading,
    isQuoteLoading,
    isQuoteSuccess,
    currentChannelId,
    currentChannelLogo,
    currentChannelName,
    currentCompanyName,
    currentCompanyMembers,
    isBuyer,
    isChannelExisting,
    isChannelListLoading,
    isConversationInfoPannel,
    isLogoPlaceholder,
    isSearchMessagesPannel,
    membersCount: currentChannel?.memberCount ?? 0,
    otherCompanyMembers,
    selectedMessage,
    startingPoint,
    handleCurrentChannelChange,
    handleResetSelectedMessage,
    handleToggleConversationInfo,
    handleToggleMessagesSearch,
    handleSearchResultClick,
  };
};

export const useCompanyQuote = ({ isBuyer, otherCompanyId }: { isBuyer: boolean; otherCompanyId?: number }) => {
  const opposingUserType = isBuyer ? 'sellerCompanyId' : 'buyerCompanyId';
  const { data, isLoading, isSuccess } = useQuoteRequestsQuery({
    enabled: !!otherCompanyId,
    sortValue: SortTypes.DEFAULT,
    status: [],
    [opposingUserType]: otherCompanyId,
  });
  if (data && data.length > 0) {
    return { quotes: data, isLoading, isSuccess };
  }
  return { quotes: [], isLoading, isSuccess };
};

const useCompanyOrder = ({ isBuyer, otherCompanyId }: { isBuyer: boolean; otherCompanyId?: number }) => {
  const opposingUserType = isBuyer ? 'sellerCompanyId' : 'buyerCompanyId';
  const { isLoading, data: orders } = useOrdersQuery({
    enabled: !!otherCompanyId,
    [opposingUserType]: otherCompanyId,
    sortValue: SortTypes.DEFAULT,
    status: [
      OrderStatus.AWAITING_FREIGHT_QUOTE,
      OrderStatus.DOCUMENTS_IN_PROGRESS,
      OrderStatus.PAYMENT_IN_PROGRESS,
      OrderStatus.READY_TO_SHIP,
    ],
  });
  return { orders, isLoading };
};
