import { useQuery } from 'react-query';

import { getChatsList } from 'app/api/SBChat';
import { useAuth } from 'hooks';

interface UseChatsListQueryProps {
  buyerCompanyId?: number;
  sellerCompanyId?: number;
  enabled?: boolean;
  isSelectEnabled?: boolean;
  refetchInterval?: number;
  userMessages?: boolean;
}

export const useChatsListQuery = ({
  buyerCompanyId,
  sellerCompanyId,
  enabled = false,
  isSelectEnabled = false,
  refetchInterval,
  userMessages,
}: UseChatsListQueryProps) => {
  const { activeUser } = useAuth();
  const { data, isLoading } = useQuery(
    ['channel-list', buyerCompanyId, sellerCompanyId, activeUser?.id],
    () =>
      getChatsList({
        params: {
          buyerCompany: buyerCompanyId,
          sellerCompany: sellerCompanyId,
          userMessages,
        },
      }),
    {
      enabled,
      refetchInterval,
      ...(isSelectEnabled && {
        select: (data) => {
          if (!data.length) {
            return [{ chatId: 'NaN', members: [] }];
          }

          return data;
        },
      }),
    },
  );
  return {
    chatsList: data,
    isChatsListLoading: isLoading,
  };
};
