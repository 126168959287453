import { UserData } from '../app/api/user/types';

type StoragesKey = 'localStorage' | 'sessionStorage';

const Storages = {
  localStorage,
  sessionStorage,
};

interface PersistentStorage {
  setItem(key: string, value: unknown, storage?: StoragesKey): void;
  getItem<T = string>(key: string, storage?: StoragesKey): T | null;
  deleteItem(key: string, storage?: StoragesKey): void;
  deleteAll(storage?: StoragesKey): void;
}

export const storage: PersistentStorage = {
  setItem: (key: string, value: unknown, storage: StoragesKey = 'localStorage') => {
    if (value === undefined) {
      Storages[storage].removeItem(key);
    } else {
      Storages[storage].setItem(key, JSON.stringify(value));
    }
  },
  getItem: <T>(key: string, storage: StoragesKey = 'localStorage'): T | null => {
    const item = Storages[storage].getItem(key);

    if (item === null) return null;

    if (item === 'null') return null;
    if (item === 'undefined') return null;

    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return JSON.parse(item);
      // eslint-disable-next-line no-empty
    } catch {}

    return item as T;
  },
  deleteItem: (key: string, storage: StoragesKey = 'localStorage') => {
    Storages[storage].removeItem(key);
  },
  deleteAll: (storage: StoragesKey = 'localStorage') => Storages[storage].clear(),
};

export const updateSessionToken = (token: string): void => {
  storage.setItem('token', token);
};

export const updateSession = (activeUser: UserData | null): void => {
  storage.setItem('userRole', activeUser?.kind);
  storage.setItem('userId', activeUser?.id, 'sessionStorage');
};

const LOGIN_PATH_KEY = 'loginPath';

const keysToKeep = ['cookiePermissions', LOGIN_PATH_KEY];

export const removeSession = (): void => {
  const keepers = keysToKeep.reduce((acc, key) => {
    const val = storage.getItem(key);
    if (val !== null) {
      return { ...acc, key: val };
    }
    return acc;
  }, {});
  storage.deleteAll();
  storage.deleteAll('sessionStorage');
  Object.entries(keepers).forEach(([key, val]) => {
    storage.setItem(key, val);
  });
};

export const getAccessInfo = (key: string): string | null => storage.getItem(key);

export const getActiveUserId = (): number | null => {
  return storage.getItem<number | null>('userId', 'sessionStorage');
};

export const setSendbirdUserId = (userId: string) => {
  storage.setItem('sendbirdUserId', userId);
};

export const setSendbirdToken = (token: string) => {
  storage.setItem('sendbirdToken', token);
};

export const clearSendbirdConfig = () => {
  storage.deleteItem('sendbirdUserId');
  storage.deleteItem('sendbirdToken');
};

export const getLoginPath = (): string | null => {
  return storage.getItem<string | null>(LOGIN_PATH_KEY);
};

export const setLoginPath = (path: string) => {
  storage.setItem(LOGIN_PATH_KEY, path);
};

export const clearLoginPath = () => {
  storage.deleteItem(LOGIN_PATH_KEY);
};

const REQUEST_ACCESS_DATA_KEY = 'requestAccessData';
export const getRequestAccessData = () => {
  try {
    return JSON.parse(storage.getItem<string | null>(REQUEST_ACCESS_DATA_KEY) || '{}') as object;
  } catch {
    return {};
  }
};

export const setRequestAccessData = (items: object) => {
  storage.setItem(REQUEST_ACCESS_DATA_KEY, JSON.stringify(items));
};
