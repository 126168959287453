import { FC } from 'react';

import { Box, Divider } from '@mui/material';

import { ReactComponent as SellerIcon } from 'assets/icons/seller.svg';
import { ReactComponent as InventoryIcon } from 'assets/icons/inventory.svg';
import { ReactComponent as BuyerIcon } from 'assets/icons/buyer.svg';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg';
import { ReactComponent as CompanyProfileIcon } from 'assets/icons/companyProfile.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/logout.svg';
import { ReactComponent as AddCircleOutlineIcon } from 'assets/icons/addCircleOutline.svg';
import { ReactComponent as LockIcon } from 'assets/icons/lock.svg';
import { ReactComponent as GlobeLockIcon } from 'assets/icons/globeLock.svg';
import { ReactComponent as BookmarkIcon } from 'assets/icons/bookmark.svg';
import { LogOutModal } from 'layout/LogOutModal';
import { notificationObserver } from 'utils/observer';
import { useAuth, useDeviceType } from 'hooks';
import { useMutation } from 'react-query';
import { postAlertSystem } from 'app/api/user/service';

import { UserMenuItem } from './UserMenuItem';
import { logoutItemStyles, styles } from './styles';

interface UserMenuOptionsProps {
  isBuyer: boolean;
  isModalOpen: boolean;
  isMultiRole: boolean;
  hasMultiRole: boolean;
  changeUser: () => void;
  handleOpenCompanyProfile: () => void;
  handleCloseModal: () => void;
  handleOpenModal: () => void;
  handleOpenChangePassword: () => void;
  handleGoToInventoryPage?: () => void;
  handleGoToCreateQuotePage?: () => void;
  handleGoToGeoRestrictionsPage?: () => void;
  handleGoToSavedProductsPage?: () => void;
}

export const UserMenuOptions: FC<UserMenuOptionsProps> = ({
  isBuyer,
  isModalOpen,
  isMultiRole,
  hasMultiRole,
  changeUser,
  handleOpenCompanyProfile,
  handleCloseModal,
  handleOpenModal,
  handleOpenChangePassword,
  handleGoToCreateQuotePage,
  handleGoToInventoryPage,
  handleGoToGeoRestrictionsPage,
  handleGoToSavedProductsPage,
}) => {
  const { isMobile } = useDeviceType();
  const { activeUser } = useAuth();
  const { mutate: alertSystemRequest } = useMutation(async (message: string) => {
    await postAlertSystem(message);
  }, {});

  const onClickSeller = () => {
    notificationObserver.publish({
      type: 'warning',
      title:
        'Seller accounts are currently down for renovation. ' +
        'Don’t worry, the Sproutzo team is still watching for any orders from buyers, ' +
        'and will be in touch if any buyers place quote requests on your account. ' +
        'For any questions, please don’t hesitate to reach out to hello@sproutzo.com”',
    });
    alertSystemRequest(`User ${activeUser?.user.email || ''} clicked on the seller account`);
  };
  return (
    <>
      {isMultiRole && hasMultiRole && (
        <UserMenuItem
          icon={isBuyer ? <SellerIcon /> : <BuyerIcon />}
          text="Switch Account"
          endIcon={
            <Box sx={{ ...styles.switchArrow }}>
              <ArrowIcon />
            </Box>
          }
          menuAction={changeUser}
        />
      )}
      {!isMultiRole && hasMultiRole && (
        <UserMenuItem icon={isBuyer ? <SellerIcon /> : <BuyerIcon />} text="Seller" menuAction={onClickSeller} />
      )}
      {isMultiRole && <Divider sx={{ ...styles.divider }} />}

      <UserMenuItem icon={<CompanyProfileIcon />} text="Company Profile" menuAction={handleOpenCompanyProfile} />

      {!isBuyer && !isMobile && handleGoToInventoryPage && (
        <UserMenuItem icon={<InventoryIcon />} text="Inventory" menuAction={handleGoToInventoryPage} />
      )}
      {!isBuyer && !isMobile && handleGoToCreateQuotePage && (
        <UserMenuItem icon={<AddCircleOutlineIcon />} text="Create Quote" menuAction={handleGoToCreateQuotePage} />
      )}
      {!isBuyer && handleGoToGeoRestrictionsPage && (
        <UserMenuItem icon={<GlobeLockIcon />} text="Country Restrictions" menuAction={handleGoToGeoRestrictionsPage} />
      )}

      {isBuyer && handleGoToSavedProductsPage && (
        <UserMenuItem icon={<BookmarkIcon />} text="Saved Products" menuAction={handleGoToSavedProductsPage} />
      )}

      <UserMenuItem icon={<LockIcon />} text="Change Password" menuAction={handleOpenChangePassword} />

      <UserMenuItem icon={<LogoutIcon />} text="Log Out" wrapStyles={logoutItemStyles} menuAction={handleOpenModal} />

      {isModalOpen && <LogOutModal title="Log Out" handleClose={handleCloseModal} isOpen={isModalOpen} />}
    </>
  );
};
