import { forwardRef, useEffect, useState } from 'react';

import { Box, Popper, useForkRef } from '@mui/material';
import { Input, useAutocomplete } from '@mui/base';
import { BaseInput } from '../BaseInput';

import { styles } from './styles';

type Choice = {
  id: number;
  name: string;
};
export interface AutocompleteInputProps {
  label: string;
  field: string;
  value: number | undefined;
  description?: string;
  full?: boolean;
  choices: Choice[];
  onChange: (field: string, value: string | number) => void;
}

export const AutocompleteInput = forwardRef<HTMLDivElement, AutocompleteInputProps>(
  ({ label, field, value, choices, onChange, description }, ref) => {
    const [inputValue, setInputValue] = useState<string>('');
    useEffect(() => {
      const crop = value ? choices.find((c) => c.id === value) : undefined;
      if (crop?.name) setInputValue(crop?.name);
    }, [value, setInputValue, choices]);
    const {
      getRootProps,
      getInputProps,
      getListboxProps,
      getOptionProps,
      groupedOptions,
      popupOpen,
      anchorEl,
      setAnchorEl,
    } = useAutocomplete({
      inputValue,
      onInputChange: (_, newInputValue, reason) => {
        if (reason !== 'reset') setInputValue(newInputValue);
      },
      options: choices,
      getOptionLabel: (option) => option.name,
      onChange: (_, newValue) => {
        if (newValue?.id) onChange(field, newValue.id);
      },
    });
    const rootRef = useForkRef(ref, setAnchorEl);
    return (
      <BaseInput label={label} description={description}>
        <Box sx={styles.inputContainer} {...getRootProps()} ref={rootRef} className="Autocomplete__root">
          <Input
            ref={setAnchorEl}
            slotProps={{
              root: {
                className: 'Autocomplete__input-root',
              },
              input: {
                className: 'Autocomplete__input',
                ...getInputProps(),
              },
            }}
          />
          {anchorEl ? (
            <Popper
              disablePortal
              open={popupOpen}
              anchorEl={anchorEl}
              sx={styles.popper}
              slotProps={{
                root: { className: 'Autocomplete__popper' },
              }}
              placement="bottom-start"
              modifiers={[
                { name: 'flip', enabled: false },
                { name: 'preventOverflow', enabled: false },
              ]}
            >
              <Box component="ul" {...getListboxProps()} className="Autocomplete__listbox" sx={styles.list}>
                {(groupedOptions as Choice[]).map((option, index) => {
                  const { key, ...optionProps } = getOptionProps({ option, index });
                  return (
                    <Box
                      key={key}
                      component="li"
                      {...optionProps}
                      className="Autocomplete__option"
                      sx={styles.listItem}
                    >
                      {option.name}
                    </Box>
                  );
                })}

                {groupedOptions.length === 0 && <li className="Autocomplete__no-options">No results</li>}
              </Box>
            </Popper>
          ) : null}
        </Box>
      </BaseInput>
    );
  },
);
