import { useCallback, useLayoutEffect, useState } from 'react';

import { ClientSentMessages } from '@sendbird/uikit-react/types/types';

interface UseChatMessageSearchProps {
  handleCloseSearchPanel: () => void;
}

export const useChatMessageSearch = ({ handleCloseSearchPanel }: UseChatMessageSearchProps) => {
  const [selectedMessage, setSelectedMessage] = useState<ClientSentMessages | undefined>(undefined);
  const [startingPoint, setStartingPoint] = useState<number | undefined>(undefined);

  useLayoutEffect(() => {
    if (startingPoint && !selectedMessage) {
      setStartingPoint(undefined);
    }
  }, [selectedMessage, startingPoint]);

  const handleResetSelectedMessage = useCallback(() => {
    // empty
  }, []);

  const handleSearchResultClick = useCallback(
    (message: ClientSentMessages) => {
      setSelectedMessage(message);
      setStartingPoint(message.createdAt);
      handleCloseSearchPanel();
    },
    [handleCloseSearchPanel],
  );

  return {
    selectedMessage,
    startingPoint,
    handleResetSelectedMessage,
    handleSearchResultClick,
  };
};
