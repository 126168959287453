import { FC, useEffect, useMemo, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { Helmet } from 'react-helmet';

import { PageLoader } from 'components';
import { Footer } from 'layout/Footer';
import { siteMessages } from 'utils/siteMessages';

import { EmptyState, ProductsFiltersWrapper } from './components';
import { useProductsListPage, useFilters } from './hooks';
import { styles } from './styles';

export const ProductsListPage: FC = (): JSX.Element => {
  const { title, searchParam } = useProductsListPage();

  const {
    parsedFilters,
    parsedColors,
    parsedContinents,
    parsedResistances,
    isLoading: isFiltersLoading,
    isMaturityRange,
    isWeightRange,
    isMaturityFilter,
    isOrganicFilter,
    isWeightFilter,
    minWeightLimit,
    maxWeightLimit,
    weightStep,
    minMaturityLimit,
    maxMaturityLimit,
    maturityStep,
  } = useFilters(searchParam);

  const isEmptyResult = useMemo(
    () => parsedColors?.length <= 1 && parsedContinents?.length <= 1,
    // SPZ-1550: update
    // && parsedResistances?.length <= 1
    [parsedColors?.length, parsedContinents?.length],
  );
  const [numberOfProducts, setNumberOfProducts] = useState<number | null>(null);
  useEffect(() => setNumberOfProducts(null), [searchParam, title]);
  return (
    <Box sx={styles.pageWrap}>
      <Helmet>
        <title>{siteMessages.productListPageTitle(title)}</title>
        <meta name="description" content={siteMessages.productListPageDescription(title)} />
      </Helmet>

      <Box sx={styles.titleContainer}>
        <Typography component="h1" sx={styles.title}>
          {searchParam ? title : `${title} Seed`}
        </Typography>
        {numberOfProducts && (
          <Typography component="span" sx={styles.numberProducts}>
            {`${numberOfProducts} Results`}
          </Typography>
        )}
      </Box>

      {isFiltersLoading ? (
        <Box sx={styles.loaderWrap}>
          <PageLoader />
        </Box>
      ) : (
        <Box sx={styles.productsWrap}>
          {isEmptyResult ? (
            <Box sx={styles.emptyWrap}>
              <EmptyState query={searchParam} />
            </Box>
          ) : (
            <ProductsFiltersWrapper
              setNumberOfProducts={setNumberOfProducts}
              searchParam={searchParam}
              parsedFilters={parsedFilters}
              parsedColors={parsedColors}
              parsedContinents={parsedContinents}
              parsedResistances={parsedResistances}
              isMaturityFilter={isMaturityFilter}
              isOrganicFilter={isOrganicFilter}
              isWeightFilter={isWeightFilter}
              isMaturityRange={isMaturityRange}
              isWeightRange={isWeightRange}
              minWeightLimit={minWeightLimit}
              maxWeightLimit={maxWeightLimit}
              weightStep={weightStep}
              minMaturityLimit={minMaturityLimit}
              maxMaturityLimit={maxMaturityLimit}
              maturityStep={maturityStep}
            />
          )}
        </Box>
      )}
      {isEmptyResult && (
        <Box sx={styles.footerWrap}>
          <Footer />
        </Box>
      )}
    </Box>
  );
};
