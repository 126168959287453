import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  mobilePaper: {},
  paper: {
    position: 'fixed',
    zIndex: 1000000,
  },
  container: {
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: customTheme.custom.white,
    boxShadow: customTheme.shadow.boxShadow.standard,
    padding: '8px 0px 16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      padding: '16px',
    },
  },
  content: {
    maxWidth: '800px',
  },
  titleWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontWeight: 600,
    fontSize: '20px',
  },
  message: {
    fontWeight: 500,
    fontSize: '12px',
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '8px',
  },
};
