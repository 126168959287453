import { Box, Typography } from '@mui/material';
import { FC, MouseEvent, useCallback } from 'react';
import { CommonButton } from 'components/CommonButton';
import { CommonModal } from 'components/CommonPopup';
import { Currency, getCurrency } from 'utils/currencyFormat';
import { SxPropsTypes } from 'theme/MuiThemeProvider/types';
import { styles } from './styles';

interface SwitchCurrencyPopupProps {
  handleClose: () => void;
  isOpen: boolean;
  currency: Currency;
  handleUpdate: (currency: Currency) => void;
}

const messages = {
  description: (newCurrency: string) =>
    `This will switch all new prices to ${newCurrency}. Old prices will still be displayed in the previous currency.`,
};

export const SwitchCurrencyPopup: FC<SwitchCurrencyPopupProps> = ({
  currency,
  handleClose,
  handleUpdate,
  isOpen = false,
}) => {
  const onClickConfirm = useCallback(() => {
    handleUpdate(currency);
    handleClose();
  }, [handleUpdate, handleClose, currency]);

  const { label } = getCurrency(currency);
  const title = `Switch to currency to ${label} (${currency})?`;
  const onClickAction = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };
  return (
    <CommonModal paperStyles={styles.paper as SxPropsTypes} title={title} isOpen={isOpen} handleClose={handleClose}>
      <Typography sx={{ ...styles.description }}>{messages.description(label)}</Typography>

      <Box sx={{ ...styles.actions }} onClick={onClickAction}>
        <CommonButton variant="text" color="info" sx={{ ...styles.closeButton }} onClick={handleClose}>
          Cancel
        </CommonButton>
        <CommonButton variant="text" color="primary" sx={{ ...styles.closeButton }} onClick={onClickConfirm}>
          Confirm
        </CommonButton>
      </Box>
    </CommonModal>
  );
};
