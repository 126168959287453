import { FC } from 'react';

import { Box, Typography } from '@mui/material';
import { Member } from '@sendbird/chat/lib/__definition';

import { useAuth } from 'hooks';
import { InfoMemberItem } from '../InfoMemberItem';
import { styles } from './styles';

interface InfoMembersListProps {
  currentCompanyName: string;
  currentCompanyMembers: Member[];
  otherCompanyName: string;
  otherCompanyMembers: Member[];
}

export const InfoMembersList: FC<InfoMembersListProps> = ({
  currentCompanyName,
  currentCompanyMembers,
  otherCompanyName,
  otherCompanyMembers,
}) => {
  const { sendbirdUserId } = useAuth();
  return (
    <Box sx={styles.wrap}>
      <Box sx={styles.flexWrap}>
        <Typography sx={styles.title}>{currentCompanyName}</Typography>
        {!!otherCompanyMembers.length && <Typography sx={styles.title}>{otherCompanyName}</Typography>}
      </Box>
      <Box sx={styles.flexWrap}>
        <Box sx={styles.list}>
          {currentCompanyMembers.map((member) => (
            <InfoMemberItem
              key={member.userId}
              avatar={member.profileUrl}
              isCurrentCompany
              isCurrentUser={member.userId === sendbirdUserId}
              userName={member.nickname}
            />
          ))}
        </Box>
        {!!otherCompanyMembers.length && (
          <Box sx={styles.list}>
            {otherCompanyMembers.map((member) => (
              <InfoMemberItem key={member.userId} avatar={member.profileUrl} userName={member.nickname} />
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};
