import { useCallback } from 'react';

import FileSaver from 'file-saver';
import { Box, Typography } from '@mui/material';
import { IMainState } from '@cyntler/react-doc-viewer/dist/store/mainStateReducer';

import { DownloadButton } from '../DownloadButton';
import { styles } from './styles';

export const PreviewHeader = (state: IMainState) => {
  const fileName = state?.currentDocument?.fileName ?? '';
  const fileUri = state?.currentDocument?.uri ?? '';

  const onDownloadFile = useCallback(() => {
    FileSaver.saveAs(fileUri, fileName);
  }, [fileUri, fileName]);

  return (
    <Box sx={{ ...styles.header }}>
      <Typography sx={{ ...styles.title }}>{fileName}</Typography>
      <DownloadButton sx={{ ...styles.downloadButton }} onClick={onDownloadFile} />
    </Box>
  );
};
