import { GeneticType } from 'app/api/products/types';
import { geneticTypeLabel } from 'components/ProductCard/constants';
import { TechStatData } from 'pages/ProductDetailsPage/types';
import {
  capitalizeFirstLetter,
  capitalizeOnlyFirstLetter,
  formatCamelCaseToTitleText,
  formatNumber,
  formatQuantityAvailableUnits,
} from './formatText';

export const KEY_TECH_STATS = ['variety', 'name', 'geneticType'];
export const FULL_TECH_STATS = ['variety', 'name', 'geneticType', 'color', 'length', 'qtyAvailable'];
export const HIDE_STATS = ['quantity available', 'public pricing', 'public pricing units'];
interface StatItemData {
  name: string;
  units?: string;
  value: string;
}

interface StatItemFormatted {
  name: string;
  value: string | null;
}

const getAttributeValue = (value?: string | number | Record<string, string>): string | null => {
  let result = '';

  try {
    if (!value) return result;

    if (typeof value !== 'object') {
      result = value.toString();
    } else if (value.min) {
      if (value.max) {
        result = `${value.min} - ${value.max} ${value.units ?? ''}`;
      } else {
        result = `${value.min} ${value.units ?? ''}`;
      }
    } else if (value.max) {
      result = `${value.max} ${value.units ?? ''}`;
    }
  } catch (e) {
    /* empty */
  }

  return result;
};

const getStatItem = (
  { name, value }: StatItemData,
  data: StatItemData[],
  formatter: (item: StatItemData) => StatItemFormatted,
) => {
  const lowercaseName = name.toLowerCase();

  if (name === 'qtyAvailable' && value) {
    const units = formatQuantityAvailableUnits(data.find(({ name }) => name === 'qtyAvailableUnits')?.value);
    return { name: 'Quantity Available', value: !!value && `${formatNumber(parseInt(value, 10))} ${units}` };
  }

  if (name === 'geneticType') {
    return { name: 'Genetic Type', value: geneticTypeLabel[value as GeneticType] };
  }

  if (name === 'variety') {
    return { name: 'Type', value };
  }

  if (lowercaseName === 'color' || lowercaseName === 'length') {
    return { name: capitalizeFirstLetter(name), value: getAttributeValue(value) };
  }

  return formatter({ name, value });
};

const commonStatFormatter = ({ name, value }: StatItemData) => ({
  name: formatCamelCaseToTitleText(name),
  value: getAttributeValue(value),
});

const otherStatFormatter = ({ name, value }: StatItemData) => {
  if (name.toLowerCase() === 'farming method') {
    return { name: capitalizeFirstLetter(name), value: capitalizeFirstLetter(value.toUpperCase()) };
  }
  return {
    name: capitalizeOnlyFirstLetter(name),
    value: getAttributeValue(value),
  };
};

export const getKeyTechStats = (data: StatItemData[]): TechStatData => {
  const keyStats = KEY_TECH_STATS.map((key) =>
    data.find(({ name }) => key.toLowerCase() === name.toLowerCase()),
  ).filter((stat) => !!stat) as TechStatData;

  const result = keyStats
    .map((stat) => getStatItem(stat, data, commonStatFormatter))
    .filter(({ value }) => !!value) as TechStatData;

  return result;
};

export const getFullTechStats = (mainData: TechStatData, otherData: TechStatData): TechStatData => {
  const mainStats = FULL_TECH_STATS.map((key) =>
    mainData.find(({ name }) => key.toLowerCase() === name.toLowerCase()),
  ).filter((stat) => !!stat) as TechStatData;

  const otherStats = otherData.filter(
    ({ name }) =>
      !FULL_TECH_STATS.some((key) => key.toLowerCase() === name.toLowerCase()) && !HIDE_STATS.includes(name),
  );

  const mainStatsResult = [...mainStats]
    .map((stat) => getStatItem(stat, mainData, commonStatFormatter))
    .filter(({ value }) => !!value) as TechStatData;

  const otherStatsResult = [...otherStats]
    .map((stat) => otherStatFormatter(stat))
    .filter(({ value }) => !!value) as TechStatData;

  const result = [...mainStatsResult, ...otherStatsResult];

  return result;
};
