import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  pageWrap: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      minHeight: 'calc(100svh - 110px)',
    },
  },
  titleContainer: {
    display: 'inline-flex',
    alignItems: 'baseline',
    marginBottom: '16px',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginBottom: '8px',
    },
  },
  title: {
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '34px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '16px',
      lineHeight: '20px',
    },
  },
  numberProducts: {
    marginLeft: '8px',
    color: customTheme.custom.gray.G400,
    fontSize: '16px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '16px',
    },
  },
  loaderWrap: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      minHeight: 'calc(100svh - 110px)',
    },
  },
  productsWrap: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  emptyWrap: {
    marginTop: '24px',
  },
  footerWrap: {
    display: 'none',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'block',
      marginLeft: '-14px',
      marginRight: '-14px',
    },
  },
};
