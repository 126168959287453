import { FC } from 'react';

import { Box } from '@mui/material';

import { useSearchParams } from 'react-router-dom';
import OnboardingBanner from 'components/OnboardingBanner';
import { PageHeader } from 'components/PageHeader';
import { useDeviceType } from 'hooks';

import { EditProductsTable } from './components/EditProductsTable';
import { styles } from './styles';

const messages = {
  title: 'Inventory',
  sub: 'Edit product availability or information',
};

export const EditProductsPage: FC = () => {
  const [searchParams] = useSearchParams();
  const displayOnboardingBanner = searchParams.get('onboarding');
  const { isMobileSize } = useDeviceType();
  return (
    <Box sx={{ ...styles.container }}>
      {displayOnboardingBanner && <OnboardingBanner containerStyles={styles.bannerStyles} />}

      <PageHeader text={messages.title} subText={messages.sub} />
      {isMobileSize ? (
        <Box>Edit inventory is not available on mobile. Switch to a desktop to view and edit inventory.</Box>
      ) : (
        <EditProductsTable />
      )}
    </Box>
  );
};

export default EditProductsPage;
