import { customerApi } from '../index';
import { apiRoutes } from '../apiRoutes';
import {
  InAppNotificationsListResponse,
  InAppNotificationsListRequest,
  NotificationsTotalCount,
  PatchInAppNotification,
  CustomerNotificationsTotalCount,
} from './types';

export const getNotificationsList = async (params: InAppNotificationsListRequest) => {
  const response = await customerApi.get<InAppNotificationsListResponse>(apiRoutes.notifications._, { params });

  return response?.data;
};

export const getNotificationsTotalCount = async () => {
  const response = await customerApi.get<NotificationsTotalCount>(apiRoutes.notifications.totalCount);

  return response?.data;
};

export const getCustomerNotificationsTotalCount = async () => {
  const response = await customerApi.get<CustomerNotificationsTotalCount>(apiRoutes.notifications.customerTotalCount);

  return response?.data;
};

export const updateNotification = async (id: number, data: PatchInAppNotification) => {
  await customerApi.patch(apiRoutes.notifications.byId(id), data);
};
