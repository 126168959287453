import { FC, SyntheticEvent } from 'react';

import { Box, SxProps, Typography } from '@mui/material';

import imagePlaceholder from 'assets/images/imagePlaceholderLarge.png';
import { getUnitTypeLabel } from 'components/UnitsMenu';
import { ProductDetail } from 'app/api/products';
import { UnitsType } from 'app/api/quotes';
import { useImageHandlers } from 'hooks';

import { KeyStatsCard } from '../KeyStatsCard';
import { styles } from './styles';

interface KeyStatsSectionProps {
  data: ProductDetail;
  cropFamily?: string;
  hideButton: boolean;
  stats: { name: string; value: string }[];
  isVerticalImage: boolean | null;
  isLegend: boolean;
  onRequestQuoteClick: (quantity?: number, units?: UnitsType) => void;
  handleImageLoad: (e: SyntheticEvent) => void;
  scrollDown: () => void;
  setWidth: (width: number) => void;
}

export const KeyStatsSection: FC<KeyStatsSectionProps> = ({
  data,
  cropFamily,
  hideButton,
  isLegend,
  isVerticalImage,
  stats,
  scrollDown,
  onRequestQuoteClick,
  handleImageLoad,
  setWidth,
}) => {
  const { image, crop, inStock, isSaved, id: productId } = data;
  const minOrder = data.company.smallestShipment;
  const minOrderType = data.company.smallestShipmentType;

  const { handleImageError } = useImageHandlers();
  const isImage = !!image;
  const isVertical = isVerticalImage !== null && isVerticalImage;
  return (
    <Box sx={{ ...styles.mainWrap }}>
      <Box sx={{ ...styles.wrap }}>
        <Box sx={styles.leftWrap(isVertical, !isImage, isLegend)}>
          {!inStock && <Box sx={{ ...styles.outOfStockTag }}>Out of Stock</Box>}
          {isImage && (
            <Box component="img" src={image || ''} sx={{ ...styles.bgImage }} onError={() => handleImageError(image)} />
          )}
          <Box sx={styles.inner}>
            <Box
              component="img"
              ref={(r: HTMLImageElement | undefined) => {
                if (r) setWidth(r?.clientWidth ?? 0);
              }}
              src={image || imagePlaceholder}
              sx={{ ...styles.image(isVertical) } as SxProps}
              onError={handleImageError(imagePlaceholder)}
              onLoad={handleImageLoad}
            />
          </Box>
        </Box>
        <Box sx={{ ...styles.cardWrap }}>
          <KeyStatsCard
            crop={crop}
            scrollDown={scrollDown}
            cropFamily={cropFamily}
            inStock={inStock}
            publicPricing={data.publicPricing}
            publicPricingUnits={data.publicPricingUnits}
            hideButton={hideButton}
            onRequestQuoteClick={onRequestQuoteClick}
            stats={stats}
            productId={productId}
            isSaved={isSaved}
          />
        </Box>
      </Box>
      {!!minOrder && !!minOrderType && (
        <Typography sx={{ ...styles.minOrder }}>
          Minimum Order: {minOrder} {getUnitTypeLabel(minOrderType)}
        </Typography>
      )}
    </Box>
  );
};
