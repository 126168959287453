import { FC, MouseEvent, useState } from 'react';

import { Box, ButtonBase, Menu, MenuItem, Typography } from '@mui/material';

import { Currency, currencies, getCurrency } from 'utils/currencyFormat';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { CommonTooltip } from 'components/CommonTooltip';
import { SwitchCurrencyPopup } from 'pages/DashboardPage/components/SwitchCurrencyPopup';
import { useModal } from 'hooks';

import { styles } from './styles';

interface CurrencyBadgeProps {
  currency: Currency;
  editable?: boolean;
  displayConfirmation?: boolean;
  updateCurrency?: (currency: Currency) => void;
}

export const CurrencyBadge: FC<CurrencyBadgeProps> = ({
  currency,
  editable,
  updateCurrency,
  displayConfirmation = true,
}) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent) => {
    if (editable) {
      setAnchorEl(event.currentTarget);
    }
  };
  const [selectedCurrency, setSelectedCurrency] = useState<Currency>(currency);
  const handleClose = (e: {}) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    (e as MouseEvent)?.stopPropagation();
    setAnchorEl(null);
  };
  const { open: isModalOpen, handleOpenModal, handleCloseModal } = useModal();

  const onSelect = (e: MouseEvent, value: Currency) => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(null);
    if (displayConfirmation) {
      setSelectedCurrency(value);
      handleOpenModal();
    } else if (updateCurrency) {
      updateCurrency(value);
    }
  };

  const handleUpdate = (currency: Currency) => {
    if (updateCurrency) updateCurrency(currency);
  };

  const currencyDetails = getCurrency(currency);
  return (
    <Box sx={{ ...styles.container, ...(editable ? styles.editable : {}) }} onClick={handleClick}>
      <Typography sx={styles.label}>{currencyDetails?.abbr ?? ''}</Typography>
      {editable && (
        <>
          <CommonTooltip title="Change Currency" placement="top" sx={styles.tooltip}>
            <ButtonBase
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              sx={styles.editButton}
            >
              <EditIcon />
            </ButtonBase>
          </CommonTooltip>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
              dense: true,
              sx: styles.menu,
            }}
          >
            {currencies.map((option, idx) => (
              <MenuItem
                sx={styles.item}
                key={option.label}
                onClick={(e: MouseEvent) => onSelect(e, option.abbr)}
                divider={idx !== currencies.length - 1}
              >
                <Box sx={styles.itemAbbr}>{option.abbr}</Box>
                <Box sx={styles.itemLabel}>{option.label}</Box>
              </MenuItem>
            ))}
          </Menu>
          <SwitchCurrencyPopup
            isOpen={isModalOpen}
            handleClose={handleCloseModal}
            currency={selectedCurrency}
            handleUpdate={handleUpdate}
          />
        </>
      )}
    </Box>
  );
};
