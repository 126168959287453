import { Dispatch, FC, SetStateAction } from 'react';

import { Box } from '@mui/material';

import { FilterParams } from 'app/api/products';
import { CheckboxFilterItem } from 'components/CheckboxFilter/types';
import { Footer } from 'layout/Footer';
import { ProductsFilters } from '../ProductsFilters';
import { ProductsListWrapper } from '../ProductListWrapper';
import { StickyFiltersBar } from '../StickyFiltersBar';
import { useProductsFilters } from './hooks';
import { styles } from './styles';

interface ProductsFiltersWrapperProps {
  searchParam: string | undefined;
  parsedFilters: FilterParams | undefined;
  parsedColors: CheckboxFilterItem[];
  parsedContinents: CheckboxFilterItem[];
  parsedResistances: CheckboxFilterItem[];
  isMaturityFilter: boolean;
  isOrganicFilter: boolean;
  isWeightFilter: boolean;
  isMaturityRange: boolean;
  isWeightRange: boolean;
  minWeightLimit: number;
  maxWeightLimit: number;
  weightStep: number;
  minMaturityLimit: number;
  maxMaturityLimit: number;
  maturityStep: number;
  setNumberOfProducts: Dispatch<SetStateAction<number | null>>;
}

export const ProductsFiltersWrapper: FC<ProductsFiltersWrapperProps> = ({
  searchParam,
  parsedFilters,
  parsedColors,
  parsedContinents,
  parsedResistances,
  isMaturityFilter,
  isOrganicFilter,
  isWeightFilter,
  isMaturityRange,
  isWeightRange,
  minWeightLimit,
  maxWeightLimit,
  weightStep,
  minMaturityLimit,
  maxMaturityLimit,
  maturityStep,
  setNumberOfProducts,
}): JSX.Element => {
  const {
    control,
    setValue,
    isAnySelected,
    clearFilters,
    geneticTypeValue,
    colorValue,
    continentValue,
    sortValue,
    minWeightValue,
    maxWeightValue,
    applyWeightFilter,
    minMaturityValue,
    maxMaturityValue,
    applyMaturityFilter,
    resistancesValue,
    organicValue,
    applyResistancesFilter,
    selectedFiltersCount,
    reset,
    getValues,
  } = useProductsFilters({
    parsedFilters,
    parsedColors,
    parsedContinents,
    parsedResistances,
    searchParam,
    minWeightLimit,
    maxWeightLimit,
    minMaturityLimit,
    maxMaturityLimit,
  });

  return (
    <>
      <ProductsFilters
        control={control}
        setValue={setValue}
        isAnySelected={isAnySelected}
        clearFilters={clearFilters}
        parsedColors={parsedColors || []}
        parsedContinents={parsedContinents || []}
        parsedResistances={parsedResistances || []}
        isMaturityFilter={isMaturityFilter}
        isOrganicFilter={isOrganicFilter}
        isWeightFilter={isWeightFilter}
        isMaturityRange={isMaturityRange}
        isWeightRange={isWeightRange}
        minWeightLimit={minWeightLimit}
        maxWeightLimit={maxWeightLimit}
        weightStep={weightStep}
        minMaturityLimit={minMaturityLimit}
        maxMaturityLimit={maxMaturityLimit}
        maturityStep={maturityStep}
        searchParam={searchParam}
      />
      <ProductsListWrapper
        setNumberOfProducts={setNumberOfProducts}
        searchParam={searchParam}
        geneticTypeValue={geneticTypeValue}
        colorValue={colorValue}
        continentValue={continentValue}
        resistancesValue={resistancesValue}
        applyResistancesFilter={applyResistancesFilter}
        sortValue={sortValue}
        minWeightValue={minWeightValue}
        maxWeightValue={maxWeightValue}
        applyWeightFilter={applyWeightFilter}
        minMaturityValue={minMaturityValue}
        maxMaturityValue={maxMaturityValue}
        organicValue={organicValue}
        applyMaturityFilter={applyMaturityFilter}
      />
      <Box sx={styles.footerWrap}>
        <Footer />
      </Box>
      <Box sx={styles.stickyBarWrap}>
        <StickyFiltersBar
          control={control}
          searchParam={searchParam}
          isAnySelected={isAnySelected}
          isMaturityFilter={isMaturityFilter}
          isMaturityRange={isMaturityRange}
          isOrganicFilter={isOrganicFilter}
          isWeightFilter={isWeightFilter}
          isWeightRange={isWeightRange}
          maxMaturityLimit={maxMaturityLimit}
          minMaturityLimit={minMaturityLimit}
          maxWeightLimit={maxWeightLimit}
          minWeightLimit={minWeightLimit}
          parsedColors={parsedColors}
          parsedContinents={parsedContinents}
          maturityStep={maturityStep}
          weightStep={weightStep}
          selectedFiltersCount={selectedFiltersCount}
          clearFilters={clearFilters}
          getValues={getValues}
          reset={reset}
          setValue={setValue}
        />
      </Box>
    </>
  );
};
