import { useCallback, useMemo } from 'react';

import { generatePath, useNavigate } from 'react-router-dom';

import { ROUTES } from 'app/routes/constants';
import { UserData } from 'app/api/user/types';
import { useModal, useAuth, useDeviceType } from 'hooks';
import { AnalyticsLinkType, useLinkClickedEvent } from 'analytics';

interface UseUserMenuProps {
  onAction?: () => void;
}

export const useUserMenu = ({ onAction }: UseUserMenuProps) => {
  const { isMultiRole, hasMultiRole, isBuyer, activeUser, changeActiveUser } = useAuth();
  const navigate = useNavigate();
  const { handleTrackLinkClickEvent } = useLinkClickedEvent();
  const { open: isModalOpen, handleOpenModal, handleCloseModal } = useModal();
  const { isMobileSize } = useDeviceType();
  const {
    open: isChangeUserModalOpen,
    handleOpenModal: handleOpenChangeUserModal,
    handleCloseModal: handleCloseChangeUserModal,
  } = useModal();

  const menuTitle = useMemo(() => {
    if (!isMultiRole) {
      return (activeUser as UserData)?.user.firstName;
    }

    return isBuyer ? 'Buyer' : 'Seller';
  }, [isBuyer, activeUser, isMultiRole]);

  const isAvatartButton = !isMultiRole && isMobileSize;

  const clickChangeUser = useCallback(() => {
    onAction?.();
    handleOpenChangeUserModal();
  }, [onAction, handleOpenChangeUserModal]);

  // Note: userId here is the customer id, not user id
  const changeUser = useCallback(
    (customerId: number) => {
      onAction?.();
      handleCloseChangeUserModal();
      changeActiveUser(customerId);
    },
    [onAction, handleCloseChangeUserModal, changeActiveUser],
  );

  const handleOpenCompanyProfile = useCallback(() => {
    const path = generatePath(ROUTES.common.company, { displayId: activeUser?.company.displayId || '' });
    onAction?.();
    navigate(path);
    handleTrackLinkClickEvent({ text: 'Company Profile', type: AnalyticsLinkType.DROPDOWN, path });
  }, [activeUser?.company.displayId, onAction, handleTrackLinkClickEvent, navigate]);

  const handleOpenChangePassword = useCallback(() => {
    const path = generatePath(ROUTES.common.changePassword);
    onAction?.();
    navigate(path);
    handleTrackLinkClickEvent({ text: 'Change Password', type: AnalyticsLinkType.DROPDOWN, path });
  }, [onAction, handleTrackLinkClickEvent, navigate]);

  const handleGoToInventoryPage = useCallback(() => {
    const companyId = activeUser?.company.displayId || '';
    const path = generatePath(ROUTES.seller.edit._, {
      companyDisplayId: companyId,
    });
    onAction?.();
    navigate(path);
    handleTrackLinkClickEvent({ text: 'Inventory', type: AnalyticsLinkType.DROPDOWN, path });
  }, [activeUser?.company.displayId, onAction, handleTrackLinkClickEvent, navigate]);

  const handleGoToGeoRestrictionsPage = useCallback(() => {
    const path = ROUTES.common.onboarding.geoRestrictions;
    onAction?.();
    navigate(ROUTES.common.onboarding.geoRestrictions);
    handleTrackLinkClickEvent({ text: 'Geography Restrictions', type: AnalyticsLinkType.DROPDOWN, path });
  }, [onAction, handleTrackLinkClickEvent, navigate]);

  const handleGoToCreateQuotePage = useCallback(() => {
    const path = ROUTES.seller.create.quote;
    onAction?.();
    navigate(ROUTES.seller.create.quote);
    handleTrackLinkClickEvent({ text: 'Create Quote', type: AnalyticsLinkType.DROPDOWN, path });
  }, [onAction, handleTrackLinkClickEvent, navigate]);

  const handleGoToSavedProductsPage = useCallback(() => {
    const path = ROUTES.buyer.savedProducts;
    onAction?.();
    navigate(path);
    handleTrackLinkClickEvent({ text: 'Saved Products', type: AnalyticsLinkType.DROPDOWN, path });
  }, [onAction, handleTrackLinkClickEvent, navigate]);

  return {
    isAvatartButton,
    isBuyer,
    isModalOpen,
    isMultiRole,
    hasMultiRole,
    menuTitle,
    changeActiveUser: changeUser,
    handleCloseModal,
    handleOpenModal,
    handleOpenCompanyProfile,
    handleOpenChangePassword,
    handleGoToInventoryPage,
    handleGoToGeoRestrictionsPage,
    handleGoToCreateQuotePage,
    handleGoToSavedProductsPage,
    clickChangeUser,
    isChangeUserModalOpen,
    handleOpenChangeUserModal,
    handleCloseChangeUserModal,
  };
};
