import { AxiosError } from 'axios';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { ACTIVATION_TOKEN_ACCEPTED, ACTIVATION_TOKEN_EXPIRED } from 'app/api/auth/errorCodes';
import { createPassword, resetPasswordSubmit } from 'app/api/auth/service';
import { CreatePasswordData, CreatePasswordError, CreatePasswordRequest } from 'app/api/auth/types';
import { ROUTES } from 'app/routes/constants';
import { notificationObserver } from 'utils/observer';
import { useAuth } from 'hooks';
import { inititalValues } from './initialValues';

interface CreatePasswordFormOptions {
  isResetPassword: boolean;
}

export const useCreatePasswordForm = ({ isResetPassword }: CreatePasswordFormOptions) => {
  const { search: token } = useLocation();
  const navigate = useNavigate();

  const [formError, setFormError] = useState('');

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreatePasswordData>({
    defaultValues: inititalValues,
  });
  const { onLogin } = useAuth();

  const onError = (error: AxiosError) => {
    const errData = error?.response?.data as CreatePasswordError;

    if (errData?.token?.code === ACTIVATION_TOKEN_EXPIRED || errData?.token?.code === ACTIVATION_TOKEN_ACCEPTED) {
      notificationObserver.publish({
        type: 'error',
        title: 'Your link is expired',
      });
    } else if (errData?.password) {
      notificationObserver.publish({
        type: 'error',
        title: errData?.password?.message,
      });
    } else if (error?.message) {
      notificationObserver.publish({
        type: 'error',
        title: error.message,
      });
    }
  };

  const { mutate: resetPasswordMutation, isLoading: isResetPasswordLoading } = useMutation(
    (data: CreatePasswordRequest) => resetPasswordSubmit(data),
    {
      onSuccess: () => {
        navigate(ROUTES.buyer._);
        notificationObserver.publish({
          type: 'success',
          title: 'Your password has been changed',
        });
      },
      onError,
    },
  );

  const { mutate: createPasswordMutation, isLoading: isCreatePasswordLoading } = useMutation(
    (data: CreatePasswordRequest) => createPassword(data),
    {
      onSuccess: (data) => {
        onLogin(data);
      },
      onError,
    },
  );

  const onSubmit = (values: CreatePasswordData) => {
    const { password, newPassword } = values;

    if (password !== newPassword) {
      setFormError('Password do not match. Please try again.');
      return;
    }
    if (isResetPassword) {
      resetPasswordMutation({
        password,
        token: token?.substring(1) || '',
      });
    } else {
      createPasswordMutation({
        password,
        token: token?.substring(1) || '',
      });
    }
  };

  const onChange = () => {
    if (formError) {
      setFormError('');
    }
  };

  return {
    errors,
    control,
    onChange,
    register,
    onSubmit,
    formError,
    handleSubmit,
    isLoading: isResetPassword ? isResetPasswordLoading : isCreatePasswordLoading,
  };
};
