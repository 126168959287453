import { FC } from 'react';

import { Control } from 'react-hook-form';
import { Box } from '@mui/material';
import Channel from '@sendbird/uikit-react/Channel';
import Message from '@sendbird/uikit-react/Channel/components/Message';
import { ClientUserMessage, ClientSentMessages } from '@sendbird/uikit-react/types/types';

import { ChatFormData, SBChatMode } from 'app/api/SBChat';
import { MessageInput } from '../MessageInput';
import { SBChannelHeader } from '../SBChannelHeader';
import { SBLoader } from '../SBLoader';
import { SBMessage } from '../SBMessage';
import { getConversationStyles } from '../stylesConfig';
import { useSBConversation } from './hooks';
import { styles } from './styles';

interface SBConversationProps {
  channelUrl: string;
  control: Control<ChatFormData>;
  disableSendButton: boolean;
  isSendMessageLoading: boolean;
  mode: SBChatMode;
  otherCompanyName?: string;
  isSearchMessagesPannel?: boolean;
  selectedMessage?: ClientSentMessages;
  startingPoint?: number;
  handleSendMessage: () => void;
  handleClearMessageInput?: () => void;
  handleClose?: () => void;
  handleResetSelectedMessage?: () => void;
}

export const SBConversation: FC<SBConversationProps> = ({
  channelUrl,
  control,
  disableSendButton,
  isSendMessageLoading,
  mode,
  otherCompanyName = '',
  isSearchMessagesPannel,
  selectedMessage,
  startingPoint,
  handleSendMessage,
  handleClearMessageInput,
  handleClose,
  handleResetSelectedMessage,
}) => {
  const customStyles = getConversationStyles(mode);

  const { highlightedMessageId, isLoading, isSystemMessagesHidden, queries, handleSwitchMessagesView } =
    useSBConversation({
      channelUrl,
      mode,
      selectedMessage,
      startingPoint,
      handleClearMessageInput,
      handleResetSelectedMessage,
    });

  return (
    <Box sx={{ ...styles.chatWrap(customStyles) }}>
      <Channel
        channelUrl={channelUrl}
        queries={queries}
        highlightedMessage={selectedMessage?.messageId}
        isLoading={isLoading}
        isReactionEnabled={false}
        startingPoint={startingPoint}
        renderChannelHeader={() => (
          <SBChannelHeader
            companyName={otherCompanyName}
            mode={mode}
            channelUrl={channelUrl}
            handleClose={handleClose}
          />
        )}
        renderMessage={({ message }) => {
          return (
            <Message
              key={message.messageId}
              message={message}
              renderMessage={() => {
                const isHighlighted = !!isSearchMessagesPannel && message.messageId === highlightedMessageId;

                return <SBMessage message={message as ClientUserMessage} mode={mode} isHighlighted={isHighlighted} />;
              }}
            />
          );
        }}
        renderMessageInput={() => (
          <MessageInput
            mode={mode}
            control={control}
            disableSendButton={disableSendButton}
            isMessagesPage={mode === SBChatMode.MESSAGES_PAGE}
            isQuoteDetailsPopup={mode === SBChatMode.QUOTE_DETAILS}
            isSystemMessagesHidden={isSystemMessagesHidden}
            isSendMessageLoading={isSendMessageLoading}
            handleSendMessage={handleSendMessage}
            handleSwitchMessagesView={handleSwitchMessagesView}
          />
        )}
        renderPlaceholderLoader={() => <SBLoader isFullHeight={mode === SBChatMode.MESSAGES_PAGE} />}
      />
    </Box>
  );
};
