import { FC } from 'react';

import { Box, Divider } from '@mui/material';
import ChannelList from '@sendbird/uikit-react/ChannelList';

import { ChannelListEmptyState } from '../ChannelListEmptyState';
import { SBChannelListHeader } from '../SBChannelListHeader';
import { SBChannelPreview } from '../SBChannelPreview';
import { SBLoader } from '../SBLoader';
import { useSBChannellList } from './hooks';
import { styles } from './styles';

interface SBChannellListProps {
  currentChannelId: string | null;
  handleCurrentChannelChange: (id: string) => void;
}

export const SBChannellList: FC<SBChannellListProps> = ({ currentChannelId, handleCurrentChannelChange }) => {
  const {
    chatsList,
    control,
    isChatsListLoading,
    isSearchByChannelName,
    isQueryString,
    isEmptyList,
    listQuery,
    clearSearchField,
    handleSortChannels,
  } = useSBChannellList();

  if (isChatsListLoading) {
    return <SBLoader isFullHeight />;
  }

  return (
    <Box sx={styles.wrap}>
      <SBChannelListHeader control={control} clearSearchField={clearSearchField} />
      <Divider />
      {isEmptyList ? (
        <ChannelListEmptyState isSearchByChannelName={isSearchByChannelName} isQueryString={isQueryString} />
      ) : (
        <ChannelList
          disableAutoSelect
          onChannelSelect={(channel) => {
            if (channel?.url) handleCurrentChannelChange(channel?.url);
          }}
          renderChannelPreview={({ channel }) => (
            <SBChannelPreview
              channel={channel}
              isSelected={channel.url === currentChannelId}
              channelData={chatsList?.find(({ chatId }) => chatId === channel.url)}
              handleSelectChannel={handleCurrentChannelChange}
            />
          )}
          sortChannelList={handleSortChannels}
          renderPlaceHolderEmptyList={() => (
            <ChannelListEmptyState isSearchByChannelName={isSearchByChannelName} isQueryString={isQueryString} />
          )}
          renderPlaceHolderLoading={() => <SBLoader isFullHeight />}
          queries={{
            channelListQuery: listQuery,
          }}
        />
      )}
    </Box>
  );
};
