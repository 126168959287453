import { apiRoutes } from '../apiRoutes';
import { CompanyData, PatchCompanyData, PatchTeamMemberData, SearchCompanyData } from './types';
import { customerApi, fileUploadApi } from '../index';

export const getCompanyData = async (displayId: string) => {
  const response = await customerApi.get<CompanyData>(apiRoutes.company.byId(displayId));

  return response?.data;
};

export const searchCompanies = async (query: string) => {
  const response = await customerApi.get<SearchCompanyData[]>(apiRoutes.company._, { params: { query } });
  return response?.data;
};

export const patchCompanyData = async (displayId: string, data: PatchCompanyData) => {
  await customerApi.patch(apiRoutes.company.byId(displayId), data);
};

export const postCompanyImage = async (displayId: string, image: File) => {
  await fileUploadApi.post(apiRoutes.company.imageById(displayId), { image });
};

export const patchCompanyTeamMemberData = async (id: number, data: PatchTeamMemberData) => {
  await customerApi.patch(apiRoutes.teamMember.byId(id), data);
};

export const postCompanyTeamMemberImage = async (id: number, image: File) => {
  await fileUploadApi.post(apiRoutes.teamMember.imageById(id), { image });
};
