import { FC, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { useQuery } from 'react-query';

import { useAuth } from 'hooks';
import { CommonModal } from 'components';
import { getCustomerNotificationsTotalCount } from 'app/api/notifications';

import { styles } from './styles';

interface ChangeActiveUserModalProps {
  isOpen: boolean;
  handleClose: () => void;
  changeActiveUser: (id: number) => void;
}

export const ChangeActiveUserModal: FC<ChangeActiveUserModalProps> = ({ isOpen, handleClose, changeActiveUser }) => {
  const { users, activeUser } = useAuth();
  const onClickRow = (id: number) => {
    changeActiveUser(id);
    handleClose();
  };
  const [customerNotificationCount, setCustomerNotificationCount] = useState<{ [customerId: number]: number }>({});
  useQuery(['user-customer-notification-count', activeUser?.user.id], () => getCustomerNotificationsTotalCount(), {
    enabled: isOpen,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: (data) => {
      setCustomerNotificationCount(
        data.reduce((acc, { customerId, totalCount }) => ({ ...acc, [customerId]: totalCount }), {}),
      );
    },
  });
  return (
    <CommonModal title="Change Active User" isOpen={isOpen} handleClose={handleClose} paperStyles={styles.modalPaper}>
      <Box sx={styles.container}>
        {users
          .filter((u) => u.id !== activeUser?.id)
          .filter((u) => u.isActive)
          .map((user, i) => (
            <Box key={user.id} sx={styles.row(i !== 0)} onClick={() => onClickRow(user.id)}>
              <Box sx={styles.text}>
                <Typography sx={styles.companyName}>{user.company.name}</Typography>
                <Typography sx={styles.kind}>{`${user.kind}, ${user.jobTitle}`}</Typography>
              </Box>
              {customerNotificationCount[user.id] > 0 && (
                <Box sx={styles.notificationCount}>{customerNotificationCount[user.id]}</Box>
              )}
            </Box>
          ))}
      </Box>
    </CommonModal>
  );
};
