import { Sender } from '@sendbird/chat/lib/__definition';
import { ClientUserMessage } from '@sendbird/uikit-react/types/types';

export enum SBChatMode {
  QUOTE_DETAILS = 'QUOTE_DETAILS',
  ORDER_DETAILS = 'ORDER_DETAILS',
  COMPANY_MODAL = 'COMPANY_MODAL',
  MESSAGES_PAGE = 'MESSAGES_PAGE',
}

export interface SBClientUserMessage extends ClientUserMessage {
  sender: Sender;
}

export interface SBFileUserMessage extends ClientUserMessage {
  type: string;
}

export interface SBMemberMetaData {
  company_id?: string;
  company_logo?: string;
  company_name?: string;
}

export interface ChatFormData {
  message: string;
  files: Array<{ file: File }>;
}

export interface CreateChatTokenResponse {
  token: string;
}

export enum SBMessageCustomType {
  SYSTEM = 'system',
  USUAL = 'usual',
}

export interface NotificationsSettingsForm {
  all: boolean;
  messages: boolean;
}

export enum MuteStatus {
  MUTE_ALL = 'MUTE_ALL',
  MUTE_ALL_EXCEPT_MESSAGES = 'MUTE_ALL_EXCEPT_MESSAGES',
  MUTE_MESSAGES = 'MUTE_MESSAGES',
  NO_MUTE = 'NO_MUTE',
}

export interface ChatMember {
  chatId: string;
  firstName: string;
  lastName: string;
}

export interface Member {
  member: ChatMember;
  isMute: boolean;
  isAdmin: boolean;
  muteStatus: MuteStatus;
}

export interface Channel {
  chatId: string;
  members: Member[];
}

export interface MuteChannelRequest {
  id: string;
  params: {
    muteStatus: MuteStatus;
  };
}

export interface GetChatInfoDataRequest {
  id: string;
}

export interface GetChatsListRequest {
  params: {
    buyerCompany?: number;
    sellerCompany?: number;
    userMessages?: boolean;
  };
}

export interface CreateChannel {
  buyerCompany: number;
  sellerCompany: number;
}

export interface SBChannelMetaData {
  buyer_company_id?: string;
  buyer_company_logo?: string;
  buyer_company_name?: string;
  seller_company_id?: string;
  seller_company_logo?: string;
  seller_company_name?: string;
}

export enum ConversationSearchType {
  CHANNEL_NAME = 'CHANNEL_NAME',
  USER_NICKNAME = 'USER_NICKNAME',
}

export interface ConversationSearchForm {
  query: string;
  searchType: ConversationSearchType;
}
