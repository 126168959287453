import { AUTH_PAGES } from 'app/api';
import { ROUTES } from 'app/routes/constants';
import { setLoginPath } from './storage';

export const saveLoginPath = () => {
  const PAGES_TO_IGNORE = AUTH_PAGES.concat(ROUTES.buyer._);
  const { pathname, search } = window.location;
  if (pathname === '/' || PAGES_TO_IGNORE.some((pg) => pg === pathname)) {
    return;
  }
  setLoginPath(pathname + search);
};
