import { useEffect } from 'react';

import { Navigate, Outlet } from 'react-router-dom';

import { ROUTES } from 'app/routes/constants';
import { useAuth } from 'hooks';
import { saveLoginPath } from 'utils/saveLoginPath';
import { useQuoteRequestDetailsRouteHandlers, useChatDetailsRouteHandlers } from './hooks';

export const ProtectedRoute = () => {
  const { token, activeUser, clearUserData } = useAuth();

  const { shouldNavigateQuoteDetail, mobileQuoteDetailPath } = useQuoteRequestDetailsRouteHandlers();
  const { mobileChatDetailPath, shouldNavigateChatDetail } = useChatDetailsRouteHandlers();

  useEffect(() => {
    if (!token && activeUser) {
      clearUserData();
    }
  }, [token, clearUserData, activeUser]);

  if (!token) {
    saveLoginPath();
    return <Navigate to={ROUTES.buyer._} />;
  }

  if (shouldNavigateQuoteDetail) {
    return <Navigate to={mobileQuoteDetailPath} />;
  }

  if (shouldNavigateChatDetail) {
    return <Navigate to={mobileChatDetailPath} />;
  }

  return <Outlet />;
};
