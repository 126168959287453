import { FC, useCallback, useState } from 'react';

import { Box, IconButton, Menu } from '@mui/material';

import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrowDown.svg';
import { ReactComponent as AvatarIcon } from 'assets/icons/avatarFilled.svg';
import { ChangeActiveUserModal } from 'components/ChangeActiveUser';
import { CommonButton, UserMenuOptions } from 'components';
import { usePathnameChange, useUserMenu } from 'hooks';
import { styles } from './styles';

export const UserMenu: FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = !!anchorEl;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  usePathnameChange({ handleAction: handleClose });

  const {
    isAvatartButton,
    isBuyer,
    isModalOpen,
    isMultiRole,
    hasMultiRole,
    menuTitle,
    clickChangeUser,
    handleCloseModal,
    handleOpenCompanyProfile,
    handleOpenModal,
    handleOpenChangePassword,
    handleGoToCreateQuotePage,
    handleGoToInventoryPage,
    handleGoToGeoRestrictionsPage,
    handleGoToSavedProductsPage,
    isChangeUserModalOpen,
    handleCloseChangeUserModal,
    changeActiveUser,
  } = useUserMenu({
    onAction: handleClose,
  });

  return (
    <>
      {isAvatartButton ? (
        <Box sx={styles.iconButtonWrap}>
          <IconButton onClick={handleClick}>
            <AvatarIcon />
          </IconButton>
        </Box>
      ) : (
        <CommonButton sx={{ ...styles.menuButton }} onClick={handleClick}>
          <span>{menuTitle}</span>
          <Box sx={{ ...styles.menuButtonIcon }}>
            <ArrowDownIcon />
          </Box>
        </CommonButton>
      )}
      <Menu
        sx={{ ...styles.menuWrap }}
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: -8,
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <UserMenuOptions
          isBuyer={isBuyer}
          isModalOpen={isModalOpen}
          isMultiRole={isMultiRole}
          hasMultiRole={hasMultiRole}
          changeUser={clickChangeUser}
          handleCloseModal={handleCloseModal}
          handleOpenCompanyProfile={handleOpenCompanyProfile}
          handleGoToCreateQuotePage={handleGoToCreateQuotePage}
          handleOpenModal={handleOpenModal}
          handleOpenChangePassword={handleOpenChangePassword}
          handleGoToInventoryPage={handleGoToInventoryPage}
          handleGoToGeoRestrictionsPage={handleGoToGeoRestrictionsPage}
          handleGoToSavedProductsPage={handleGoToSavedProductsPage}
        />
      </Menu>
      <ChangeActiveUserModal
        isOpen={isChangeUserModalOpen}
        handleClose={handleCloseChangeUserModal}
        changeActiveUser={changeActiveUser}
      />
    </>
  );
};
