import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: (hasTopBorder: boolean) => ({
    cursor: 'pointer',
    ...(hasTopBorder && {
      borderTop: '1px solid #E0E0E0',
    }),
    ':hover': {
      backgroundColor: customTheme.custom.gray.G100,
    },
    transition: customTheme.transition.default,
    margin: '0px -20px',
    padding: '8px 20px',
    display: 'flex',
    justifyContent: 'space-between',
  }),
  companyName: {
    fontSize: '16px',
    fontWeight: 500,
  },
  kind: {},
  modalPaper: {},
  text: {},
  notificationCount: {
    width: '24px',
    height: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    backgroundColor: customTheme.custom.red.light,
    color: customTheme.custom.white,
  },
};
