import { FC } from 'react';

import { Box, SxProps, Typography } from '@mui/material';
import { ReactComponent as ErrorIcon } from '../../assets/icons/error.svg';
import { styles } from './styles';

export interface FormErrorProps {
  text: string;
  sx?: SxProps;
  textSx?: SxProps;
}

export const FormError: FC<FormErrorProps> = ({ text, sx, textSx }) => (
  <Box sx={{ ...styles.wrap(sx) }}>
    <Box sx={{ ...styles.errorIcon }}>
      <ErrorIcon />
    </Box>
    <Typography sx={{ ...styles.errorText, ...textSx }}>{text}</Typography>
  </Box>
);
