import { FC, useMemo } from 'react';

import { Box, Typography } from '@mui/material';

import { Address } from 'app/api/company/types';
import { Order } from 'app/api/orders/types';
import { QuoteProductStatus, UnitsType } from 'app/api/quotes/types';
import { CommonButton, CompanyChatModal } from 'components';
import { currencyFormat } from 'utils/currencyFormat';
import { getUnitDisplayValue } from 'pages/QuoteRequestPage/utils';
import { AddressInfo } from '../AddressInfo';
import { CollapseWrap } from '../CollapseWrap';
import { CropsList } from '../CropsList';
import { ChatModal } from '../ChatModal';
import { CancelOrderModal } from '../CancelOrderModal';
import { useOrderInfo } from './useOrderInfo';
import { styles } from './styles';

interface OrderInfoProps extends Order {
  isBuyer: boolean;
  isChatModalOpen: boolean;
  isMobileSize: boolean;
  deliveryAddress?: Address;
  shippingWeight?: number | null;
  handleCloseChatModal: () => void;
}

export const OrderInfo: FC<OrderInfoProps> = ({
  id,
  isBuyer,
  isChatModalOpen,
  isMobileSize,
  quote,
  status,
  deliveryAddress,
  shippingWeight,
  handleCloseChatModal,
}) => {
  const {
    isCancelModalOpen,
    handleOpenCancelModal,
    handleCloseCancelModal,
    isCancelButton,
    isSendButtonDisabled,
    control,
    handleCancelOrder,
    isCancelOrderLoading,
    handleConfirm,
    isSendMessageLoading,
    handleSubmit,
  } = useOrderInfo({
    channel: quote.chatId,
    status,
    orderId: id,
    quoteId: Number(quote.id),
    seller: quote.sellerCompany,
    isSampleRequest: quote.isSampleType,
  });

  const isSampleOrder = quote.isSampleType;

  const availableProducts = useMemo(
    () => quote.quoteproducts.filter((product) => product.status === QuoteProductStatus.AVAILABLE) ?? [],
    [quote.quoteproducts],
  );

  const shippingWeightLabel = shippingWeight ? `${shippingWeight} kg` : 'Pending';
  const hasThousandSeed = availableProducts.some((qp) => qp.amountType === UnitsType.THOUSAND_SEEDS);

  let totalWeight;
  if (hasThousandSeed) {
    totalWeight = shippingWeightLabel;
  } else {
    const weightInKg = availableProducts.reduce((acc, qp) => {
      if (qp.amountType === UnitsType.KG) {
        return acc + (qp.amount ?? 0);
      }
      return acc + (qp.amount ?? 0) * 0.453592;
    }, 0);
    totalWeight = `${weightInKg.toFixed(2)} ${getUnitDisplayValue(UnitsType.KG, true)}`;
  }

  const minHeightMin = availableProducts.length < 2 ? '368px' : '412px';
  const minHeight = availableProducts.length < 3 ? minHeightMin : '460px';

  const MainWrap = isMobileSize ? CollapseWrap : Box;

  return (
    <MainWrap sx={styles.mainWrap} collapsedSize="50px" gridRow={4}>
      <Box sx={{ ...styles.wrap(minHeight) }}>
        <Typography sx={{ ...styles.title }} component="h3">
          {isSampleOrder && 'Sample '}Order Details
        </Typography>

        {!!availableProducts.length && <CropsList products={availableProducts} isOrderPage />}

        <AddressInfo company={isBuyer ? quote.sellerCompany : quote.buyerCompany} deliveryAddress={deliveryAddress} />

        <Box sx={{ ...styles.total }}>
          <Typography sx={{ ...styles.totalTitle, ...styles.totalText }}>Total Weight</Typography>
          <Typography sx={{ ...styles.totalAmount, ...styles.totalText }}>{totalWeight}</Typography>
        </Box>

        {!!quote?.totalPrice && (
          <Box sx={{ ...styles.total }}>
            <Typography sx={{ ...styles.totalTitle, ...styles.totalText }}>Total Price</Typography>
            <Typography sx={{ ...styles.totalPrice, ...styles.totalText }}>
              {currencyFormat(Number(quote.totalPrice))}
            </Typography>
          </Box>
        )}

        <Box sx={{ ...styles.actions }}>
          {isCancelButton && (
            <CommonButton
              disableRipple
              variant="text"
              color="inherit"
              sx={{ ...styles.cancelButton }}
              onClick={handleOpenCancelModal}
            >
              Cancel Order
            </CommonButton>
          )}
        </Box>
      </Box>

      {isMobileSize ? (
        <CompanyChatModal
          channelUrl={quote?.chatId ?? ''}
          control={control}
          disableSendButton={isSendButtonDisabled}
          isOpen={isChatModalOpen}
          isSendMessageLoading={isSendMessageLoading}
          otherCompanyName={isBuyer ? quote.sellerCompany.name : quote.buyerCompany.name}
          handleClose={handleCloseChatModal}
          handleSendMessage={handleSubmit(handleConfirm)}
        />
      ) : (
        <ChatModal
          otherCompanyName={isBuyer ? quote.sellerCompany.name : quote.buyerCompany.name}
          chatId={quote?.chatId ?? ''}
          control={control}
          handleSendMessage={handleSubmit(handleConfirm)}
          isLoading={isSendMessageLoading}
          disableSendButton={isSendButtonDisabled}
        />
      )}
      <CancelOrderModal
        isOpen={isCancelModalOpen}
        handleClose={handleCloseCancelModal}
        handleCancel={handleCancelOrder}
        isLoading={isCancelOrderLoading}
      />
    </MainWrap>
  );
};
